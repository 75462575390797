import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import ServiceCard from "./ServiceCard";

const BusinessServicesCards = ({ servicesArray }) => {
  const imgData = useStaticQuery(graphql`
    {
      card1: file(relativePath: { eq: "cards/business-services/thumbnail-payment-processing-services-101823.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      card2: file(relativePath: { eq: "cards/business-services/thumbnail-hr-payroll-services-101823.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      card3: file(relativePath: { eq: "cards/business-services/thumbnail-point-of-sale-101823.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      card4: file(relativePath: { eq: "cards/business-services/thumbnail-customer-engagement-101823.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      card5: file(relativePath: { eq: "cards/business-services/thumbnail-collect-early-102523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      card6: file(relativePath: { eq: "cards/business-services/thumbnail-cashflow-management-101823.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      card7: file(relativePath: { eq: "cards/business-services/thumbnail-accounting-services-101823.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      card8: file(relativePath: { eq: "workperks/thumbnail-work-perks-082624.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  // Same names as in ServiceNavigation.js
  const BusinessServicesData = [
    {
      name: "Payment Processing Services",
      description:
        "Accept payments anywhere with the most up-to-date technology so you can help more customers. Your business can accept payments online, mobile, through a kiosk, at the counter, contactless, and in the field.",
      listData: {
        items: [
          {
            id: 1,
            class: "mb-1",
            text: "Terminal Payments (card present)"
          },
          {
            id: 2,
            class: "mb-1",
            text: "E-commerce (card not present)"
          },
          {
            id: 3,
            class: "mb-1",
            text: "Mobile"
          },
          {
            id: 4,
            class: "mb-1",
            text: "Bill payments"
          },
          {
            id: 5,
            class: "mb-1",
            text: "Solutions for platforms"
          },
          {
            id: 6,
            class: "mb-1",
            text: "Solutions for developers"
          },
          {
            id: 7,
            class: "mb-1",
            text: "Industry-leading security"
          },
          {
            id: 8,
            class: "mb-1",
            text: "Comprehensive breach warranty"
          }
        ]
      },
      linkUrl: "/business-banking/business-services/payment-processing-services",
      image: {
        imgVariable: imgData.card1.childImageSharp.gatsbyImageData,
        altText: "Woman checking out at vegetable stand with a mobile phone."
      }
    },
    {
      name: "HR and Payroll Services",
      description:
        "A complete all-in-one payroll program, Heartland Payroll helps with job listings, hiring, employee handbooks and compliance, payroll processing, and direct deposit.",
      listData: {
        items: [
          {
            id: 1,
            class: "mb-1",
            text: "Payroll processing"
          },
          {
            id: 2,
            class: "mb-1",
            text: "POS integration/timekeeping (labor management)"
          },
          {
            id: 3,
            class: "mb-1",
            text: "HR solutions"
          },
          {
            id: 4,
            class: "mb-1",
            text: "Applicant tracking"
          },
          {
            id: 5,
            class: "mb-1",
            text: "Tax credit screening"
          },
          {
            id: 6,
            class: "mb-1",
            text: "Electronic onboarding"
          }
        ]
      },
      linkUrl: "/business-banking/business-services/hr-and-payroll-services",
      image: {
        imgVariable: imgData.card2.childImageSharp.gatsbyImageData,
        altText: "Smiling businesswoman working on laptop."
      }
    },
    {
      name: "Point of Sale and Inventory Systems",
      description:
        "Easily monitor inventory and manage sales to give you more time to grow your business. Solutions are available for small businesses up to large enterprises with multiple locations.",
      listData: {
        items: [
          {
            id: 1,
            class: "mb-1",
            text: "Mobile and terminal systems"
          },
          {
            id: 2,
            class: "mb-1",
            text: "Systems designed specifically for restaurant, hospitality, and liquor merchants"
          },
          {
            id: 3,
            class: "mb-1",
            text: "Small business through enterprise-wide systems"
          },
          {
            id: 4,
            class: "mb-1",
            text: "Cloud-based option with 30-day backup"
          }
        ]
      },
      linkUrl: "/business-banking/business-services/pos-and-inventory-systems",
      image: {
        imgVariable: imgData.card3.childImageSharp.gatsbyImageData,
        altText: "Woman using a tablet in a warehouse."
      }
    },
    {
      name: "Customer Engagement",
      description:
        "Create loyalty and gift card programs for your customers. Also, see in-depth analytics on your customers and products to help make marketing and promotion decisions with a powerful email marketing system to keep customers coming back for more.",
      listData: {
        items: [
          {
            id: 1,
            class: "mb-1",
            text: "Customer intelligence and analytics"
          },
          {
            id: 2,
            class: "mb-1",
            text: "Real-time CRM"
          },
          {
            id: 3,
            class: "mb-1",
            text: "Email Marketing"
          },
          {
            id: 4,
            class: "mb-1",
            text: "Digitally managed loyalty programs for small businesses and large enterprises"
          },
          {
            id: 5,
            class: "mb-1",
            text: "Social reputation management"
          },
          {
            id: 6,
            class: "mb-1",
            text: "Digital gift cards"
          },
          {
            id: 7,
            class: "mb-1",
            text: "Digital wallet (stored value) cards"
          }
        ]
      },
      linkUrl: "/business-banking/business-services/customer-engagement",
      image: {
        imgVariable: imgData.card4.childImageSharp.gatsbyImageData,
        altText: "Man walking through a shopping mall with bags."
      }
    },
    {
      name: "CollectEarly",
      title: "CollectEarly&trade;",
      description:
        "Did you know 82% of small businesses fail because of cash flow issues? Get paid in four HOURS versus four weeks to optimize your cash flow.",
      listData: {
        items: [
          {
            id: 1,
            class: "mb-1",
            text: "User-friendly platform so you can save time and get back to doing what you love"
          },
          {
            id: 2,
            class: "mb-1",
            text: "Works with your current invoicing software"
          },
          {
            id: 3,
            class: "mb-1",
            text: "Create an invoice, or drag and drop an invoice into FINSYNC"
          },
          {
            id: 4,
            class: "mb-1",
            text: "Funds can get deposited into your synced WaFd account in hours"
          },
          {
            id: 5,
            class: "mb-1",
            text: "Low 2.5% fee (less than fees to accept credit cards)"
          },
          {
            id: 6,
            class: "mb-1",
            text: "For on-site jobs, utilize the ability to invoice and upload before and after photos on the spot to save you time matching jobs with photos"
          }
        ]
      },
      linkUrl: "/business-banking/business-services/collect-early",
      getStartedLink: "https://www.finsync.com/wafdbank/",
      image: {
        imgVariable: imgData.card5.childImageSharp.gatsbyImageData,
        altText: "Happy businesswoman in an office working on a laptop."
      }
    },
    {
      name: "Cash Flow Management",
      description:
        "Gain more over your cash flow, allowing you to seize growth opportunities to scale up your business, minimize financial risks, like economic downturns or late payments, and help your business stand the test of time.",
      listData: {
        items: [
          {
            id: 1,
            class: "mb-1",
            text: "User-friendly, especially when connected with your WaFd business accounts"
          },
          {
            id: 2,
            class: "mb-1",
            text: "See all bank and credit card activity in one place"
          },
          {
            id: 3,
            class: "mb-1",
            text: "Visualize business cash flow with projections so you can plan for seasonality"
          },
          {
            id: 4,
            class: "mb-1",
            text: "Plan for upcoming income and expenses with intuitive charts and calendars"
          },
          {
            id: 5,
            class: "mb-1",
            text: "Better predict when funds will move into and out of the business"
          },
          {
            id: 6,
            class: "mb-1",
            text: "Track profitability in real time"
          },
          {
            id: 7,
            class: "mb-1",
            text: "Training and support resources are available with dedicated support representatives based in the US"
          }
        ]
      },
      linkUrl: "/business-banking/business-services/cash-flow-management",
      getStartedLink: "https://www.finsync.com/wafdbank/",
      image: {
        imgVariable: imgData.card6.childImageSharp.gatsbyImageData,
        altText: "Business owner and employee in a shop reviewing financials."
      }
    },
    {
      name: "Accounting Automation",
      description:
        "Harness the power of automation to simplify your accounting processes and gain better insights into your business finances so you can get back to doing what you love, like having dinner with your family or going after your next bid.",
      listData: {
        items: [
          {
            id: 1,
            class: "mb-1",
            text: "User-friendly platform to seamlessly bridge your accounting with your business operations"
          },
          {
            id: 2,
            class: "mb-1",
            text: "Take the guesswork out of accounting with clear and concise workflows"
          },
          {
            id: 3,
            class: "mb-1",
            text: "Make single or recurring journal entries"
          },
          {
            id: 4,
            class: "mb-1",
            text: "Customizable chart of accounts"
          },
          {
            id: 5,
            class: "mb-1",
            text: "Track income and expenses by department, location, and associate"
          },
          {
            id: 6,
            class: "mb-1",
            text: "Cash and accrual reports"
          },
          {
            id: 7,
            class: "mb-1",
            text: "Robust documentation retention to save you time hunting for files"
          },
          {
            id: 8,
            class: "mb-1",
            text: "Transaction reports, audit trail, and more"
          }
        ]
      },
      linkUrl: "/business-banking/business-services/accounting-automation",
      getStartedLink: "https://www.finsync.com/wafdbank/",
      image: {
        imgVariable: imgData.card7.childImageSharp.gatsbyImageData,
        altText: "Business owner doing accounting in his shop."
      }
    },
    {
      name: "WaFd Bank Work Perks",
      description:
        "Offer perks to your employees at no cost, with any WaFd business checking account and 10 or more employees.",
      listData: {
        items: [
          {
            id: 1,
            class: "mb-1",
            text: "Online and mobile banking with bill pay and mobile deposit"
          },
          {
            id: 2,
            class: "mb-1",
            text: "Complimentary first box of checks"
          },
          {
            id: 3,
            class: "mb-1",
            text: "Free 3x5 Safe Deposit Box where available"
          },
          {
            id: 4,
            class: "mb-1",
            text: "Get an extra 0.10% over our published CD rates"
          },
          {
            id: 5,
            class: "mb-1",
            text: 'Complimentary access to <a href="/personal-banking/greenlight-kids-debit-card" id="perks-greenlight-link">Greenlight</a> to help kids learn how to manage their own money'
          },
          {
            id: 6,
            class: "mb-1",
            text: "Up to $5,000 off mortgage, refinance or custom construction loan origination fees*"
          },
          {
            id: 7,
            class: "mb-1",
            text: "1.5% discount for 6 months on published Home Equity Line of Credit rates**"
          },
          {
            id: 9,
            class: "mb-1",
            text: 'Access to <a href="https://wafdbank.banzai.org/wellness/collections" id="perks-banzai-external-link" target="_blank" rel="noopener noreferrer">Banzai!</a> an interactive finance platform for all ages with tools, calculators, and more'
          },
          {
            id: 10,
            class: "mb-1",
            text: "Debit card with fee-free access to thousands of MoneyPass<sup>&trade;</sup> ATMs"
          },
          {
            id: 11,
            class: "mb-1",
            text: 'Person-to-person payments with <a href="/personal-banking/mobile-banking-app/zelle" id="perks-zelle-link">Zelle</a><sup>&reg;</sup>'
          }
        ]
      },
      getStartedLink: "/business-banking/business-checking-account/perks",
      image: {
        imgVariable: imgData.card8.childImageSharp.gatsbyImageData,
        altText: "Group of laughing coworkers."
      }
    }
  ];

  let filteredData = BusinessServicesData;

  if (servicesArray) {
    filteredData = BusinessServicesData.filter((card, index) => {
      return servicesArray.includes(index);
    });
  }

  return (
    <div className="row">
      {filteredData.map((serviceData, index) => {
        const isLastTwoItems =
          filteredData.length === index + 1 || // Check for last item
          (filteredData.length % 2 === 0 && index >= filteredData.length - 2); // Check for even length and last 2 items

        const mb = isLastTwoItems ? "mb-0" : "mb-4";
        return (
          <div key={index} className={"col-md-6 mt-2 " + mb}>
            <ServiceCard card={serviceData} index={1} />
          </div>
        );
      })}
    </div>
  );
};

export default BusinessServicesCards;
BusinessServicesCards.defaultProps = {
  // Number array, if it exists it shows only the selected numbers
  // EX [0, 1] shows only first two service cards
  servicesArray: null
};
